import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
} from "@mui/material";
import { Table, TableContainer, Box, Button, Typography } from "@mui/material";
import { styled, FormControlLabel, Radio } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Subscription.css";
import {
  cancelSubscription,
  createCheckout,
  getSubscriptionData,
  selectCancelLoader,
  selectCancelStatus,
  selectPaymentLoader,
  selectSubLoader,
  selectSubscriptions,
  setCheckotUrl,
} from "./reducer";
import { useDispatch, useSelector } from "react-redux";
import Success from "../Common/PresentationToast/Success";
import Failure from "../Common/PresentationToast/Failure";
import Loader from "../Common/Loader/Loader";
import { ArrowDropDown } from "@mui/icons-material";
import { getUser } from "../Login/reducer";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& th:first-of-type": {
    borderRadius: "20px 0px 0px 20px",
    position: "sticky",
    left: 0,
    zIndex: 900,
    background: "#1f2327",
    minWidth: "180px",
    textWrap: "wrap",
  },
  "& th:last-child": {
    borderRadius: "0px 20px 20px 0px",
  },
  "& td:first-of-type": {
    borderRadius: "20px 0px 0px 20px",
    position: "sticky",
    left: 0,
    zIndex: 900,
    background: "#1f2327",
    minWidth: "180px",
    textWrap: "wrap",
  },
  "& td:last-child": {
    borderRadius: "0px 20px 20px 0px",
  },
}));

function SubscriptionTable() {
  const dispatch = useDispatch();
  const subscriptionData = useSelector(selectSubscriptions);
  const paymentLoader = useSelector(selectPaymentLoader);
  const subLoader = useSelector(selectSubLoader);
  const CancelLoader = useSelector(selectCancelLoader);
  const CancelStatus = useSelector(selectCancelStatus);

  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [show,setShow]=useState(false);

  const handleClose = () => {
    setOpen(false);
    setStatus("");
    dispatch(setCheckotUrl());
    clearStatusFromUrl();
  };

  useEffect(() => {
    const getQueryParam = (name) => {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get(name);
    };

    const statusParam = getQueryParam("status");
    setStatus(statusParam);
    setOpen(true);
  }, []);

  useEffect(() => {
    dispatch(getSubscriptionData());
    dispatch(getUser());
    setShow(false)
  }, [dispatch, CancelStatus]);

  const clearStatusFromUrl = () => {
    const urlWithoutStatus =
      window.location.pathname +
      window.location.search.replace("?status=" + status, "");
    window.history.replaceState({}, document.title, urlWithoutStatus);
  };

  const createPayment = () => {
    dispatch(createCheckout());
  };
  const subscriptionCancel = (id) => {
    dispatch(cancelSubscription(id));
  };

  const formattedDate = (timestamp) => {
    const dateValue = new Date(timestamp).toDateString();
    return dateValue.slice(4);
  };

  return (
    <>
      {subLoader ? (
        <Loader />
      ) : (
        <>
          {subscriptionData.length > 0 &&
          subscriptionData.some((sub) => sub.status === "active") ? (
            <Box className="subscrTable">
              <Typography variant="body1" className="subscr-title">
                Apply remaining period to another publication
              </Typography>
              <TableContainer
                sx={{
                  display: { xs: "none", sm: "none", md: "block", lg: "block" },
                }}
              >
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <TableCell>Subscription ID</TableCell>
                      <TableCell>Plan ID</TableCell>
                      <TableCell>Start date</TableCell>
                      <TableCell>Status</TableCell>
                    </StyledTableRow>
                    <TableRow>
                      <TableCell
                        colSpan="4"
                        sx={{
                          bgcolor: "transparent !important",
                          height: "5px !important",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptionData.map((sub) => (
                      <>
                        <StyledTableRow key={sub.subscription_id}>
                          <TableCell>
                            <FormControlLabel
                              value=""
                              control={
                                <Radio
                                  fontSize="large"
                                  checked={true}
                                  sx={{ color: "#D286FF" }}
                                />
                              }
                              label={sub.subscription_id}
                            />
                          </TableCell>
                          <TableCell>
                            {sub.plan_id}
                            {/* <span className="month-text">Every Month</span> */}
                          </TableCell>
                          <TableCell>{formattedDate(sub.start_date)}</TableCell>
                          <TableCell>
                            <span
                              style={{
                                color:
                                  sub.status === "active" ? "green" : "red",
                                textTransform: "capitalize",
                              }}
                            >
                              {sub.status}
                            </span>
                          </TableCell>
                        </StyledTableRow>
                        <br />
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionData.map((sub, index) => (
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <Accordion sx={{ bgcolor: "#1f2327", color: "#fff" }}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDown sx={{ color: "#fff" }} />}
                    >
                      <Typography>Subscription {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography>
                        Subscription ID : {sub.subscription_id}
                      </Typography>
                      <Typography>Plan ID : {sub.plan_id}</Typography>
                      <Typography>
                        Start date : {formattedDate(sub.start_date)}
                      </Typography>
                      <Typography>
                        Status :{" "}
                        <span
                          style={{
                            color: sub.status === "active" ? "green" : "red",
                          }}
                        >
                          {sub.status}
                        </span>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
              <Button
                variant="conatained"
                className="subsc-btn"
                onClick={() => setShow(true)}
                sx={{
                  width: { xs: "230px", sm: "300px", md: "300px", lg: "300px" },
                }}
              >
                Cancel Subscription
              </Button>
            </Box>
          ) : (
            <Box className="subscr-card-cantainer">
              <Card
                sx={{
                  p: { xs: 4, sm: 6, md: 6, lg: 6 },
                  width: { xs: "100%", sm: "380px", md: "430px", lg: "430px" },
                }}
                className="subscr-card"
              >
                <Box>
                  <Typography className="subscr-card-pro-text">Pro</Typography>
                  <Typography
                    className="subscr-card-dollor-text"
                    variant="h2"
                    sx={{
                      fontSize: {
                        xs: "50px",
                        sm: "65px",
                        md: "65px",
                        lg: "65px",
                      },
                    }}
                  >
                    28 $
                  </Typography>
                </Box>
                <Box
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                      md: "18px",
                      lg: "18px",
                    },
                  }}
                >
                  <Typography
                    variant="body3"
                    className="subscr-card-content-title"
                  >
                    Unlimited access to AI at your fingertips
                  </Typography>
                  <ul className="subscr-card-content-list">
                    <li>Unlimited Presentations</li>
                    <li>Premium templates</li>
                    <li>Templates with images</li>
                    <li>Early access to AI features</li>
                    <li>$1 per download (One-time fee per download)</li>
                  </ul>
                </Box>
              </Card>
              <Button
                sx={{
                  width: { xs: "100%", sm: "400px", md: "430px", lg: "430px" },
                }}
                className="subscr-card-btn"
                onClick={createPayment}
              >
                {paymentLoader ? (
                  <div className="paymentLoader"></div>
                ) : (
                  "Upgrade to pro"
                )}
              </Button>
            </Box>
          )}
        </>
      )}
      {status === "success" ? (
        <Success
          open={open}
          handleClose={handleClose}
          status={status}
          message="Subscription Successful!"
        />
      ) : (
        <></>
      )}
      {status === "failure" ? (
        <Failure
          open={open}
          handleClose={handleClose}
          status={status}
          message="Subscription Failed!"
        />
      ) : (
        <></>
      )}

      <Modal open={show}>
        <Box
          className="modal-box2"
          sx={{ width: { xs: "320px", sm: "480px", md: "540px", lg: "540px" } }}
        >
          {CancelLoader ? (
            <Box className="modal-content" sx={{ flexDirection: "column" }}>
              <div class="loader-inner">
                <div class="line-spin-fade-loader">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <Typography
                variant="h5"
                className="modal-text"
                sx={{
                  mt: 4,
                  color: "#FFFFFF80",
                  fontSize: { xs: "18px", sm: "20px", md: "22px", lg: "22px" },
                }}
              >
                Please wait while the request is being processed...
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                className="modal-text"
                variant="h5"
                sx={{
                  fontSize: { xs: "18px", sm: "20px", md: "22px", lg: "22px" },
                }}
              >
                Are you sure you want to cancel the subscription?
              </Typography>
              <Box className="modal-content" mt={3}>
                <Button
                  className="confirm-btn"
                  onClick={() =>
                    subscriptionCancel(subscriptionData[0]?.subscription_id)
                  }
                >
                  Confirm
                </Button>
                <Button className="close-btn" onClick={() => setShow(false)}>
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default SubscriptionTable;
