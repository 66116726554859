import api from "./axiosInstance";

export const createUserData = () => {
  return api.post(`/users/`);
};

export const getUserData = () => {
  return api.get(`/users/`);
};

export const getPresentation = () => {
  return api.get(`/presentation/`);
};

export const makePresentation = (params) => {
  const {
    topic,
    instructions,
    number_of_pages,
    negative_prompt,
    templateName,
    file_name
  } = params;
  const data = { topic, instructions, number_of_pages, negative_prompt , file_name };
  return api.post(`/presentation/?template_name=${templateName}`, data);
};

export const getTemplates = () => {
  return api.get(`/presentation/templates`);
};

export const makePPTAgent = (params) => {
  return api.post(`/presentation/make_ppt_agent`, params);
};

export const getSubscriptionData = () => {
  return api.get(`/payments/subscriptions`);
};
export const createCheckout = () => {
  return api.post(`/payments/create-checkout-session`);
};
export const cancelSubscription = (params) => {
  const data = {
    subscription_id: params,
  };
  return api.post(`/payments/cancel-subscription`, data);
};

export const deletePPT = (params) => {
  return api.delete(`/presentation/?presentation_id=${params}`);
};

export const getPPTLimit = () => {
  return api.get(`/presentation/limit`);
};

export const sendUrlData = (parms) => {
  return api.post(`/files/ingest-url`,parms)
}

export const sendFileData = (parms) => {
  const formData = new FormData();
  formData.append('file', parms.file);

   return api.post(
    `/files/ingest-file?file_name=${parms.file_name}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

export const getFilesData = () => {
  return api.get(`/files/`)
}

export const deleteFile = (params) => {
  return api.delete(`/files/${params}`)
}

export const downloadFile = (params) => {
  return api.get(`/files/download/${params.id}`, {
    responseType: 'blob',
  });
};

export const createCheckoutConsumable = (params) => {
  return api.post(`/payments/create-checkout-session-consumable?presentation_id=${params}`);
};