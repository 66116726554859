import { Grid } from "@mui/material";
import { Box, Card, Typography } from "@mui/material";
import React from "react";
import "./Pricing.css";
import PaymentForm from "./PaymentForm";

function Payment() {
    return (
        <>
            <Box
                className="payment-container"
                sx={{
                    paddingY: "30px",
                    paddingX: { xs: "27px", sm: "50px", md: "60px", lg: "60px" },
                    marginTop: { xs: '100px', sm: '100px', md: '12%', lg: '12%' }
                }}
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        className="d-flex"
                    >
                        <Card
                            sx={{
                                p: { xs: 4, sm: 6, md: 6, lg: 6 },
                                width: { xs: "100%", sm: "400px", md: "430px", lg: "430px" },
                            }}
                            className="pricing-card"
                        >
                            <Box>
                                <Typography className="pricing-card-pro-text">
                                    Pro
                                </Typography>
                                <Typography
                                    className="pricing-card-dollor-text"
                                    variant='h2'
                                    sx={{
                                        fontSize: { xs: "50px", sm: "65px", md: "65px", lg: "65px" },
                                    }}
                                >
                                    28 $
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "18px" },
                                }}
                            >
                                <Typography variant="body3" className="pricing-card-content-title" >
                                    Unlimited access to AI at your fingertips
                                </Typography>
                                <ul className="pricing-card-content-list">
                                    <li>Unlimited Presentations</li>
                                    <li>Premium templates</li>
                                    <li>Templates with images</li>
                                    <li>Early access to AI features</li>
                                    <li>$1 per download (One-time fee per download)</li>
                                </ul>
                            </Box>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        className="d-flex"
                    >
                        <PaymentForm />
                    </Grid>
                    <Grid item xs={0} sm={0} md={0.5} lg={0.5}></Grid>
                </Grid>
            </Box>
        </>
    );
}

export default Payment;
