import { useEffect, useState } from "react";
import Failure from "../Common/PresentationToast/Failure";
import Success from "../Common/PresentationToast/Success";
import { useNavigate } from "react-router-dom";

export const CheckoutStatus = () => {
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getQueryParam = (name) => {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get(name);
    };

    const statusParam = getQueryParam("status");
    setStatus(statusParam);
    setOpen(true);
  }, []);

  const handleClose = () => {
      setOpen(false);
      setStatus("");
      navigate("/presentation");
  };
  return (
    <div>
      {status === "success" ? (
        <Success
          open={open}
          handleClose={handleClose}
          status={status}
          message="Payment Successful!"
        />
      ) : (
        <></>
      )}
      {status === "failure" ? (
        <Failure
          open={open}
          handleClose={handleClose}
          status={status}
          message="Payment Failed!"
        />
      ) : (
        <></>
      )}
    </div>
  );
};
