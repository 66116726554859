import React from 'react';
import { Box, Card, Typography } from "@mui/material";
import "./Pricing.css";
// import { useNavigate } from "react-router-dom";
import transition from '../Common/Transition/Transition';

const Pricing = () => {
  // const navigate = useNavigate();
  // const startPayment = () => {
  //   console.log("clicked")
  //   navigate("/price/payment")
  // }
  return (
    <Box className="pricing-container" sx={{ marginTop: { xs: '100px', sm: '100px', md: '12%', lg: '12%' } }}>
      <Box sx={{ maxWidth: "700px", textAlign: "center" }} mb={10}>
        <Typography
          className="pricing-text"
          sx={{
            fontSize: { xs: "16px", sm: "16px", md: "20px", lg: "22px" },
          }}
        >
          Clear and simple pricing plans to cater to different needs. <br />
          Comparison table highlighting the features included in each plan.
        </Typography>
      </Box>
      <Card
        sx={{
          p: { xs: 4, sm: 6, md: 6, lg: 6 },
          width: { xs: "100%", sm: "380px", md: "410px", lg: "410px" },
        }}
        className="pricing-card"
      >
        <Box>
          <Typography className="pricing-card-pro-text">
            Pro
          </Typography>
          <Typography
            className="pricing-card-dollor-text"
            variant='h2'
            sx={{
              fontSize: { xs: "50px", sm: "65px", md: "65px", lg: "65px" },
            }}
          >
            28 $
          </Typography>
        </Box>
        <Box
          sx={{
            fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "18px" },
          }}
        >
          <Typography variant="body3" className="pricing-card-content-title" >
            Unlimited access to AI at your fingertips
          </Typography>
          <ul className="pricing-card-content-list">
            <li>Unlimited Presentations</li>
            <li>Premium templates</li>
            <li>Templates with images</li>
            <li>Early access to AI features</li>
            <li>$1 per download (One-time fee per download)</li>
          </ul>
        </Box>
      </Card>
      {/* <Button
        sx={{ width: { xs: "100%", sm: "400px", md: "430px", lg: "430px" } }}
        className="pricing-card-btn"
        onClick={startPayment}
      >
        Start
      </Button> */}
    </Box>
  )
}

export default transition(Pricing);