import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "../../api/index";

const initialState = {
  presentationData: [],
  pptLimit: 0,
  ui: {
    presentation: {
      loading: false,
    },
    presentationForm: {
      loading: false,
      status: "idle",
      message: "",
    },
    delete: {
      message: "",
    },
    files: {
      filesData:[],
      loading: false,
    },
  },
};

const getPresentation = createAsyncThunk("get/presentations", async () => {
  const res = await API.getPresentation();
  return res.data;
});

const makePresentation = createAsyncThunk(
  "make/presentation",
  async (params, { rejectWithValue }) => {
    try {
      const res = await API.makePresentation(params);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data.detail);
    }
  }
);
const createCheckoutConsumable = createAsyncThunk(
  "presnetation/checkout",
  async (params, { rejectWithValue }) => {
    try {
      const res = await API.createCheckoutConsumable(params);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data.detail);
    }
  }
);

const deletePPT = createAsyncThunk("delete/presentation", async (params) => {
  const res = await API.deletePPT(params);
  return res.data;
});

const getPPTLimit = createAsyncThunk("get/PPTLimit", async () => {
  const res = await API.getPPTLimit();
  return res.data;
});
const getFilesData = createAsyncThunk("get/filesData", async () => {
  const res = await API.getFilesData();
  return res.data;
});

export const presentationSlice = createSlice({
  name: "presentation",
  initialState,
  reducers: {
    setDeleteMessage: (state) => {
      state.ui.delete.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPresentation.pending, (state, action) => {
        state.ui.presentation.loading = true;
      })
      .addCase(getPresentation.fulfilled, (state, action) => {
        state.ui.presentation.loading = false;
        state.presentationData = action.payload;
      })
      .addCase(getPresentation.rejected, (state, action) => {
        state.ui.presentation.loading = false;
      });
    builder
      .addCase(makePresentation.pending, (state, action) => {
        state.ui.presentationForm.loading = true;
        state.ui.presentationForm.status = "pending";
        state.ui.presentationForm.message = "";
      })
      .addCase(makePresentation.fulfilled, (state, action) => {
        state.ui.presentationForm.loading = false;
        state.ui.presentationForm.status = "success";
        state.ui.presentationForm.message = "";
      })
      .addCase(makePresentation.rejected, (state, action) => {
        state.ui.presentationForm.loading = false;
        state.ui.presentationForm.status = "rejected";
        state.ui.presentationForm.message = action.payload;
      });
    builder
      .addCase(deletePPT.pending, (state, action) => {
        state.ui.delete.message = "";
      })
      .addCase(deletePPT.fulfilled, (state, action) => {
        state.ui.delete.message = action.payload.message;
      })
      .addCase(deletePPT.rejected, (state, action) => {
        state.ui.delete.message = "";
      });
    builder
      .addCase(getPPTLimit.pending, (state, action) => {
        state.pptLimit = 0;
      })
      .addCase(getPPTLimit.fulfilled, (state, action) => {
        state.pptLimit = action.payload.limit;
      })
      .addCase(getPPTLimit.rejected, (state, action) => {
        state.pptLimit = 0;
      });
    builder
      .addCase(getFilesData.pending, (state, action) => {
        state.ui.files.loading = true;
      })
      .addCase(getFilesData.fulfilled, (state, action) => {
        state.ui.files.loading = false;
        state.ui.files.filesData = action.payload;
      })
      .addCase(getFilesData.rejected, (state, action) => {
        state.ui.files.loading = false;
      });
    builder
      .addCase(createCheckoutConsumable.pending, (state, action) => {
        state.ui.files.loading = true;
      })
      .addCase(createCheckoutConsumable.fulfilled, (state, action) => {
        state.ui.files.loading = false;
      })
      .addCase(createCheckoutConsumable.rejected, (state, action) => {
        state.ui.files.loading = false;
      });
  },
});

export { getPresentation, makePresentation, deletePPT, getPPTLimit , getFilesData, createCheckoutConsumable };

export const { setDeleteMessage } = presentationSlice.actions;

export const selectPrsentation = (state) => state.presentation.presentationData;
export const selectLoading = (state) =>
  state.presentation.ui.presentation.loading;
export const selectStatus = (state) =>
  state.presentation.ui.presentationForm.status;
export const makepresentationLoader = (state) =>
  state.presentation.ui.presentationForm.loading;
export const selectDeleteMessage = (state) =>
  state.presentation.ui.delete.message;
export const PptErrorMessage = (state) =>
  state.presentation.ui.presentationForm.message;
export const pptLimit = (state) => state.presentation.pptLimit;
export const selectFilesLoading = (state) =>
  state.presentation.ui.files.loading;
export const selectFilesData = (state) =>
  state.presentation.ui.files.filesData;

export default presentationSlice.reducer;
