import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import "./Presentation.css";
import { useOutletContext } from "react-router-dom";
import {
  createCheckoutConsumable,
  deletePPT,
  getPPTLimit,
  getPresentation,
  pptLimit,
  selectDeleteMessage,
  selectLoading,
  selectPrsentation,
  setDeleteMessage,
} from "./reducer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Common/Loader/Loader";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Skeleton from "react-loading-skeleton";
import { Delete } from "@mui/icons-material";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import Lock from "@mui/icons-material/Lock";
import { toast } from "react-toastify";

function Presentaion() {
  const [open] = useOutletContext();
  const dispatch = useDispatch();
  const presentationData = useSelector(selectPrsentation);
  const loader = useSelector(selectLoading);
  const userID = useSelector((state) => state.auth.user_id);
  const deleteMessage = useSelector(selectDeleteMessage);
  const PptLimit = useSelector(pptLimit);
  // console.log("open", open);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getPPTLimit());
        dispatch(getPresentation());
        dispatch(setDeleteMessage());
      } catch (error) {
        console.error("Error :", error);
      }
    };
    fetchData();
  }, [dispatch, deleteMessage]);

  const getTimeDifference = (createdAt) => {
    const currentTime = moment().utc();
    const createdAtTime = moment.utc(createdAt);
    const timeDifference = Math.abs(
      Math.round((currentTime - createdAtTime) / (1000 * 60))
    );

    if (timeDifference < 60) {
      return `${timeDifference} mins ago`;
    } else if (timeDifference < 1440) {
      const hoursAgo = Math.floor(timeDifference / 60);
      return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
    } else {
      const daysAgo = Math.floor(timeDifference / 1440);
      return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
    }
  };

  const deletePresentation = (id) => {
    dispatch(deletePPT(id));
  };

  const handlePayment = async (id) => {
    console.log("id: ", id);
    try {
      const res = await dispatch(createCheckoutConsumable(id));
      if (res.type === "presnetation/checkout/fulfilled") {
        window.location.href = res.payload.checkout_url;
      } else {
        toast.error("Payment link failed");
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Container sx={{ mt: { xs: 5, sm: 5, md: 10, lg: 10 }, mb: "30px" }}>
          {PptLimit !== -1 && (
            <Typography
              variant="body1"
              sx={{
                pr: { xs: 0, sm: 8, md: 8, lg: 10 },
                mb: 3,
                textAlign: {
                  xs: "center",
                  sm: "right",
                  md: "right",
                  lg: "right",
                },
              }}
            >
              Available limit: {PptLimit}
            </Typography>
          )}
          <Grid
            container
            sx={{
              // pl: { xs: "0px", sm: "30px", md: "0px", lg: open ? 0 : 15 },
              flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
            }}
            className="slide-container"
          >
            {presentationData.length > 0 ? (
              presentationData
                .slice()
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((slide) => (
                  <Grid
                    item
                    lg={4}
                    md={open ? 6 : 4}
                    sm={6}
                    xs={12}
                    key={slide.id}
                  >
                    <Box
                      className="slide-img-box"
                      sx={{ position: "relative" }}
                    >
                      {/* Lock Overlay */}
                      {!slide.is_paid && (
                        <Box
                          onClick={() => {
                            handlePayment(slide.id);
                          }}
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 4,
                            bgcolor: "rgba(0, 0, 0, 0.5)",
                            zIndex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            pointerEvents: "auto",
                            borderRadius: "24px",
                            cursor: "pointer",
                          }}
                        >
                          <Lock color="#fff" size={24} />{" "}
                        </Box>
                      )}

                      {slide.is_paid && (
                        <>
                          <Box className="iconBox">
                            <Tooltip
                              title="Download"
                              placement="left"
                              enterTouchDelay={1000}
                            >
                              <a
                                href={`https://backend-dot-boldpreso-prd.uc.r.appspot.com/presentation/download/${slide.id}?user_id=${userID}`}
                                download={true}
                              >
                                <FileDownloadIcon className="slide-hover-icon" />
                              </a>
                            </Tooltip>
                          </Box>

                          <Box
                            className="deleteIconBox"
                            sx={{ bgcolor: "red", top: "60px" }}
                          >
                            <Tooltip
                              title="Delete"
                              placement="left"
                              enterTouchDelay={1000}
                            >
                              <Button
                                onClick={() => {
                                  deletePresentation(slide.id);
                                }}
                                disableElevation
                                disableRipple
                              >
                                <Delete className="slide-hover-icon" />
                              </Button>
                            </Tooltip>
                          </Box>
                        </>
                      )}

                      {slide.thumbnail_jpeg_base64 ? (
                        <Box
                          component="img"
                          src={`data:image/png;base64,${slide.thumbnail_jpeg_base64}`}
                          alt={slide.title}
                          sx={{
                            width: {
                              xs: "286px",
                              sm: "276px",
                              md: "256px",
                              lg: "300px",
                            },
                          }}
                          className="slideImg"
                        />
                      ) : (
                        <Skeleton
                          width={300}
                          height={165}
                          baseColor="#202020"
                          highlightColor="#444"
                          borderRadius={25}
                        />
                      )}
                    </Box>

                    <Box className="slide-content">
                      <Typography variant="h6" className="slide-name">
                        {slide?.title}
                      </Typography>
                      <Typography variant="h6" className="slide-time">
                        Created {getTimeDifference(slide?.created_at)}
                      </Typography>
                    </Box>
                  </Grid>
                ))
            ) : (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  className="d-flex helpText no-present-text"
                  sx={{ height: "80vh" }}
                >
                  <p>No Presentation to show at the moment</p>

                  <Link to="/presentation/create">
                    <button className="no-present-create-btn">
                      Create a presentation
                    </button>
                  </Link>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      )}
    </>
  );
}

export default Presentaion;
