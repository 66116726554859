import { useEffect } from "react";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home/Home";
import Feature from "./components/Feature/Feature";
import Pricing from "./components/Pricing/Pricing";
// import About from "./components/About/About";
import Login from "./components/Login/Login";
import Signup from "./components/Login/Signup";
import Layout from "./components/Layout/Layout";
import Payment from "./components/Pricing/Payment";

import Presentation from "./components/Presentation/Presentation";
import PresentationForm from "./components/PresentationForm/Form";
import AIChat from "./components/AI/Chat";
import MyFile from "./components/MyFile/MyFile";
import PresentationLayout from "./components/Layout/PresentationLayout";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

import { selectIsLoggedIn } from "./components/Login/reducer";
import { useSelector } from "react-redux";
import SubscriptionTable from "./components/Subscription/Subscription";

import { AnimatePresence } from "framer-motion";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import { CheckoutStatus } from "./components/Presentation/CheckoutStatus";

function PrivateRoute({ element, ...rest }) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return isLoggedIn ? element : <Navigate to="/login" />;
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="root">
      <ToastContainer theme="dark" />
      <AnimatePresence>
        <ScrollToTop key={1} />
        <Routes key={2}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/feature" element={<Feature />} />
            <Route path="/price" element={<Pricing />} />
            <Route path="/price/payment" element={<Payment />} />
            {/* <Route path="/about" element={<About />} /> */}
          </Route>
          <Route element={<PresentationLayout />}>
            <Route
              path="/presentation"
              element={<PrivateRoute element={<Presentation />} />}
            />
            <Route
              path="/presentation/create"
              element={<PrivateRoute element={<PresentationForm />} />}
            />
            <Route
              path="/presentation/chat"
              element={<PrivateRoute element={<AIChat />} />}
            />
            <Route
              path="/presentation/subscription"
              element={<PrivateRoute element={<SubscriptionTable />} />}
            />
              <Route 
            path="/presentation/checkout" 
            element={<CheckoutStatus />} 
          />
            <Route
              path="/presentation/MyFile"
              element={<PrivateRoute element={<MyFile />} />}
            />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
